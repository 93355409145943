import axios from 'axios';
import AuthService from "../../services/AuthService";

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  headers: {
    "container-id": "Site",
    "container-type": "Site"
  },
});

axiosConfig.interceptors.request.use(function (config) {
  const auth = new AuthService();
  const token = auth.getLocalToken();
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

export default axiosConfig;
