import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from "./locales/en/translation.json";

const options = {
  interpolation: {
    escapeValue: false,
  },

  debug: true,

  resources: {
    en: {
      locales: common_en
    }
  },

  fallbackLng: 'en',

  ns: ['locales'],

  defaultNS: 'locales',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },
};

i18n
  .use(LanguageDetector)
  .init(options);

export default i18n;
