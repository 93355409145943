import http from "../components/config/axiosContextConfig";

const getAll = (page, size) => {
  page = (typeof page !== 'undefined') ?  page : 0
  size = (typeof size !== 'undefined') ?  size : 10

  return http.get('/users', {
    params: {
      "page": page,
      "size": size
    }
  })
};

const search = (email) => {
  return http.get('/users', {
    params: {
      "search": "email~" + email
    }
  })
};

const getById = id => {
  return http.get(`/users/${id}/`)
};

const getByUsername = name => {
  return http.get(`/users/username/${name}/`, {
    headers: {
      "container-id": "Site",
      "container-type": "Site"
    },
  })
};

const create = data => {
  return http.post('/users', data)
};

const update = (id, data) => {
  return http.put(`/users/${id}`, data)
};

const remove = id => {
  return http.delete(`/users/${id}`)
};

const addGroup = (id, data) => {
  let payload = {
    "groups": [data]
  }
  return http.put(`/users/${id}/groups`, payload)
};

const removeGroup = (id, data) => {
  let payload = {
    "groups": [data]
  }
  return http.delete(`/users/${id}/groups`, { data: payload })
};

const getUserPrivileges = (username, context) => {
  return http.get(`/users/${username}/privileges`, {
    params: {
      containerId: context.context.containerId,
      containerType: context.context.containerType
    }
  })
};

const getByCustomerId = id => {
  return http.get(`/customers/${id}/users`, {
    headers: {
      "container-id": id,
      "container-type": "Customer"
    }
  })
};

const getByOrganizationId = id => {
  return http.get(`/organizations/${id}/users`, {
    headers: {
      "container-id": id,
      "container-type": "Organization"
    }
  })
};

export default {
  getAll,
  getById,
  create,
  update,
  remove,
  addGroup,
  removeGroup,
  getUserPrivileges,
  search,
  getByUsername,
  getByCustomerId,
  getByOrganizationId
};
