import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import  { AuthenticatedRoute } from './components/routes/AuthenticatedRoute';
import { UserContext } from './components/contexts/UserContext';
import AuthService from "./services/AuthService";
import UserService from "./services/UserService";
import ContextHelper from "./components/util/ContextHelper";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>
)

const Layout = React.lazy(() => import('./components/layout/Layout'));
const Login = React.lazy(() => import('./components/login/Login'));
const Page404 = React.lazy(() => import('./components/error/Page404'));
const Page500 = React.lazy(() => import('./components/error/Page500'));
class App extends Component {

  auth = new AuthService();

  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      error: {
        message: null
      },
      user: {
        username: "",
        privileges: [],
        groups: []
      }
    };
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
  }

  logout() {
    this.auth.logout();
    this.setState({
      authenticated: false,
      user: {},
      error: {
        message: null
      },
    });
  }

  handleError(err) {
    if (err.response) {
      console.log(err.response)
    }
  }

  getUserGroups = async (username) => {
    try {
      const {data} = await UserService.getByUsername(username)
      return data.groups;
    } catch (err) {
      console.log(err.message);
    }
  }

  getUserPrivileges = async (username) => {
    try {
      const context = ContextHelper.getContextBoundURL(window.location.pathname)
      const {data} = await UserService.getUserPrivileges(username, context)
      return data;
    } catch (err) {
      console.log(err.message);
    }
  }

  async login(data) {
    try {
      await this.auth.authenticate(data);
    } catch (e) {
      this.setState({
        error: {
          message: "A user could not be found with the provided credentials"
        }
      });
    }

    if (this.auth.isLoggedIn()) {
      const username = this.auth.getUserName();
      const privileges = await this.getUserPrivileges(username);
      const groups = await this.getUserGroups(username)

      this.setState({
        authenticated: true,
        user: {
          username: username,
          privileges: privileges,
          groups: groups
        }
      });
    } else {
      this.setState({
        error: {
          message: "A user could not be found with the provided credentials"
        }
      });
    }
  }

  async componentDidMount() {
    if (this.auth.isLoggedIn()) {
      const username = this.auth.getUserName();
      const privileges = await this.getUserPrivileges(username);
      const groups = await this.getUserGroups(username)

      this.setState({
        authenticated: true,
        user: {
          username: username,
          privileges: privileges,
          groups: groups
        }
      });
    } else {
      this.auth.removeToken()
    }
  }

  render() {
    const value = {
      user: this.state.user,
      error: this.state.error,
      authenticated: this.state.authenticated,
      logoutUser: this.logout,
      loginUser: this.login
    }
    return (
      <UserContext.Provider value={value}>
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <AuthenticatedRoute path="/" name="Home" component={Layout} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
      </UserContext.Provider>
    );
  }
}

export default App;
