import decode from 'jwt-decode';
import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

httpClient.interceptors.request.use(function (config) {
  const token = this.getLocalToken();
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

export default class AuthService {
  isLoggedIn() {
    const token = this.getLocalToken()
    return !!token && !this.isTokenExpired(token)
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      return (decoded.exp < Date.now() / 1000);
    }

    catch (err) {
      return false;
    }
  }

  setLocalToken(idToken) {
    localStorage.setItem('id_token', idToken)
  }

  getLocalToken() {
    return localStorage.getItem('id_token')
  }

  logout() {
    localStorage.removeItem('id_token');
  }

  removeToken() {
    localStorage.removeItem('id_token');
  }

  getToken() {
    if (!this.isLoggedIn()) {
      return null;
    }
    return decode(this.getLocalToken());
  }

  getUserName() {
    let username = null;
    let token = this.getToken();

    if (!this.isLoggedIn()) {
      return null;
    }

    if (token && token.hasOwnProperty("sub")) {
      username = token.sub;
    }
    return username;
  }

   async authenticate(credentials) {
     const headers = {
       'Accept': 'application/json',
       'Content-Type': 'application/json'
     }

     await axios.post(httpClient.defaults.baseURL + '/login', JSON.stringify(credentials), {
       headers
     }).then(response => {
       if (response.status === 200) {
         if (response.headers['x-amzn-remapped-authorization'] !== undefined && response.headers['x-amzn-remapped-authorization'].length) {
           this.setLocalToken(response.headers['x-amzn-remapped-authorization'].split(" ")[1]);
         } else {
           this.setLocalToken(response.headers.authorization.split(" ")[1]);
         }
       } else {
         throw new Error("An error has occurred " + response.status + " " + response.statusText);
       }
     }).catch(error => console.log(error));
   }
}
