const getContextBoundURL = (url) => {
  if (url !== undefined) {
    let parts = url.split('/')
    if (parts[1] === "organizations" && parts[2] !== undefined && parts.length === 3) {
      return {
        context: {
          containerId: parts[2],
          containerType: "Organization"
        }
      }
    } else if (parts[3] === "customers" && parts[4] !== undefined && parts.length === 5) {
      return {
        context: {
          containerId: parts[4],
          containerType: "Customer"
        }
      }
    } else if (parts[5] === "environments" && parts[6] !== undefined && parts.length === 7) {
      return {
        context: {
          containerId: parts[6],
          containerType: "Environment"
        }
      }
    } else {
      return {
        context: {
          containerId: "Site",
          containerType: "Site"
        }
      }
    }
  }
};

export default {
  getContextBoundURL
};
